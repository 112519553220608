import request from '@/utils/request'

// 添加实例
export function addIotCase(data) {
    return request({
      url: '/iot/case/add',
      method: 'post',
      data: data
    })
}

// 获取实例
export function getIotCase(id) {
    return request({
      url: '/iot/case/get?id=' + id,
      method: 'get'
    })
}

// 更新实例
export function updateIotCase(data) {
    return request({
      url: '/iot/case/update',
      method: 'post',
      data: data
    })
  }
  
// 删除实例
export function delIotCase(id) {
    return request({
      url: '/iot/case/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 实例列表
export function listIotCase(query) {
  return request({
    url: '/iot/case/list',
    method: 'get',
    params: query
  })
}