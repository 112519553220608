<template>
  <div class="app-container">
    <el-row>
      <el-col :span="24" class="card-box">
        <el-card>
          <div slot="header">
            <tr>
            <span v-if="form.level==1" style="font-size:13px;background-color: rgb(94,124,224);color:white;">基础版</span>
            <span v-if="form.level==2" style="font-size:13px;background-color: rgb(94,124,224);color:white;">标准版</span>
            <span v-if="form.level==3" style="font-size:13px;background-color: rgb(94,124,224);color:white;">企业版</span>            
            <span v-if="form.level==1" style="margin-left: 10px">默认</span>            
            </tr>
            <tr>
            <span v-if="form.level==1" style="display:inline-block;margin-top: 10px;font-size:13px;color:#859094;">计费模式: 按需计费(消息数)支持基本的设备接入和设备管理功能</span>
            <span v-if="form.level==2" style="display:inline-block;margin-top: 10px;font-size:13px;color:#859094;">计费模式: 按时计费(包月/包年)支持设备管理、各类企业客户场景，可根据业务模型选购更经济的平台实例</span>
            <span v-if="form.level==3" style="display:inline-block;margin-top: 10px;font-size:13px;color:#859094;">计费模式: 按时计费(包月/包年)支持完全独享的实例、更高的并发能力， 具备更高的安全性和隔离性</span>
            </tr>
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            <table cellspacing="0" style="width: 100%;">              
              <tbody>
                <tr>
                  <td><div class="cell">实例名称</div></td>
                  <td><div class="cell">{{ form.case_name }}</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">消息并发上限</div></td>
                  <td><div class="cell">{{ form.concurrent_max }} 个</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">注册设备上限</div></td>
                  <td><div class="cell">{{ form.device_max }} 个</div></td>
                  <td/>
                </tr>
                <tr>
                  <td><div class="cell">是否当前实例</div></td>
                  <td><div class="cell" v-if="form.is_choose>=1">是当前选择使用的实例</div><div class="cell" v-else>不是当前选择的实例</div></td>
                  <td/>
                </tr>               
                <tr>
                  <td><div class="cell">创建时间</div></td>
                  <td><div class="cell">{{createtime}}</div></td>
                  <td/>
                </tr>  
              </tbody>
            </table>
          </div>          
          <div  style = "clear:both;" ></div >         
        </el-card>
      </el-col>
     <el-col :span="24" class="card-box">
        <el-card>
          <div slot="header">
            <span>接入信息</span>
          </div>
          <div class="el-table el-table--enable-row-hover el-table--medium">
            <table cellspacing="0" style="width: 100%;">
              <thead>
                <tr>
                  <th class="is-leaf" align="left"><div class="cell">接入类型</div></th>
                  <th class="is-leaf" align="left"><div class="cell">接入协议</div></th>
                  <th class="is-leaf" align="left"><div class="cell">接入地址</div></th>
                  <th class="is-leaf" align="left"><div class="cell">说明</div></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in protolist" :key="item" >
                  <td style="display:inline-block;margin-top:10px"><div class="cell" v-if="item.type==0">应用接入</div><div class="cell" v-if="item.type==1">设备接入</div></td>
                  <td><div class="cell">{{item.protocol_name}}({{item.address}})</div></td>
                  <td><div class="cell">{{item.path}}</div></td>
                  <td><div class="cell">{{item.remark}}</div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-card>
      </el-col>      
    </el-row>
  </div>
</template>

<script>
import {  getIotCase} from "@/api/iot/case";
import { listIotCaseProtocol } from "@/api/iot/caseprotocol";
export default {
  name: "caseinfo",
  data() {
    return {
      // 加载层信息
      total: 0,
      caseid:0,
      apphttps:443,
      appamqps:5671,
      devicecoap:5683,
      devicecoaps:5684,
      devicemqtt:1883,
      devicemqtts:8883,
      devicehttps:8943,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,       
      },
      loading: [],  
      // 是否显示弹出层
      open: false,
      form: {},  
      protolist:[],
      createtime:""
    };
  },
  created() {
    this.caseid = this.$route.params && this.$route.params.id;      
    this.getCase(this.caseid);   
    this.openLoading();
  },
  methods: {
    getCase(id) {
      getIotCase(id).then(response => {
        this.form = response.data; 
        if (this.form.level < 3){
          this.protolist  =[{
            type:0,
            protocol_name:"HTTPS",
            address:this.apphttps,
            path:this.form.base_address,
            remark:""
          },{
            type:0,
            protocol_name:"AMQPS",
            address:this.appamqps,
            path:this.form.base_address,
            remark:""
          },{
            type:1,
            protocol_name:"CoAP",
            address:this.devicecoap,
            path:this.form.base_address,
            remark:""
          },{
            type:1,
            protocol_name:"CoAPS",
            address:this.devicecoaps,
            path:this.form.base_address,
            remark:""
          },{
            type:1,
            protocol_name:"HTTPS",
            address:this.devicehttps,
            path:this.form.base_address,
            remark:""
          },{
            type:1,
            protocol_name:"MQTT",
            address:this.devicemqtt,
            path:this.form.base_address,
            remark:""
          },{
            type:1,
            protocol_name:"MQTTS",
            address:this.devicemqtts,
            path:this.form.base_address,
            remark:""
          }];
          this.loading.close();
        }  
        if(this.form.level == 3){
          var protocolparams={
            pageNum:1,
            pageSize:10,
            case_id:this.form.Id,
            base_level:3,
            is_default:0
          };
          listIotCaseProtocol(protocolparams).then(response =>{ 
            for (var index=0;index<response.data.list.length;index++){
              this.protolist.push({
                type:response.data.list[index].type,
                protocol_name:response.data.list[index].protocol_name,
                address:response.data.list[index].address,
                path:this.form.base_address+response.data.list[index].path,
                remark:response.data.list[index].remark
              });                           
            }           
            this.loading.close();
          }).catch(function (error){
            console.log(error);
          });
        }   
        this.createtime=this.timestampToTime(this.form.create_time);        
      }).catch(function (error){
          console.log(error);
        });  
    },     
    timestampToTime (cjsj) {
        var date = new Date(cjsj*1000) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-'
        var D = date.getDate() + ' '
        var h = date.getHours() + ':'
        var m = date.getMinutes() + ':'
        var s = date.getSeconds()
        return Y+M+D+h+m+s        
    },
    // 打开加载层
    openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "获取中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    }
  }
};
</script>