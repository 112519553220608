import request from '@/utils/request'

// 添加实例协议
export function addIotCaseProtocol(data) {
    return request({
      url: '/iot/caseprotocol/add',
      method: 'post',
      data: data
    })
}

// 获取实例协议
export function getIotCaseProtocol(id) {
    return request({
      url: '/iot/caseprotocol/get?id=' + id,
      method: 'get'
    })
}

// 更新实例协议
export function updateIotCaseProtocol(data) {
    return request({
      url: '/iot/caseprotocol/update',
      method: 'post',
      data: data
    })
  }
  
// 删除实例协议
export function delIotCaseProtocol(id) {
    return request({
      url: '/iot/caseprotocol/del',
      method: 'post',
      data: {
        ids: id
      }
    })
  }
// 实例协议列表
export function listIotCaseProtocol(query) {
  return request({
    url: '/iot/caseprotocol/list',
    method: 'get',
    params: query
  })
}